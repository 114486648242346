import PropTypes from "prop-types"
import React from "react"
import { LocaleContext } from '../components/layout'
import useTranslations from '../components/useTranslations'

const ContactForm = ({ tag, type }) => {
  const { locale } = React.useContext(LocaleContext)
  const { 
    pedir_cita, 
    texto_form,
    lunes,
    martes,
    viernes,
    sabado,
    field_name,
    field_phone,
    field_email,
    field_message,
    cita_previa } = useTranslations();

  const form_actions = {
    'es-pedircita': 'https://paolacarolina.cercles.io/form-submit.php?curl=T2dKZOpI',
    'en-pedircita': 'https://paolacarolina.cercles.io/form-submit.php?curl=LS4oZxnM',
    'es-contacto': 'https://paolacarolina.cercles.io/form-submit.php?curl=aUFi4RIN',
    'en-contacto': 'https://paolacarolina.cercles.io/form-submit.php?curl=ZUUyAC9C',
  };

  const tag_split = tag ? tag.split('/') : [];

  const isES = locale === 'es'

  return (
    <>
      <h2 className="dialog__title">{pedir_cita}<br/>—</h2>
      <p><a href="tel:+34933824154">(+34) 93 382 41 54</a>
      <br/>C/ del Rosselló, 256, 1º 2ª Barcelona, 08037 
      <br/>{lunes}: 12-20h,
      <br/>{martes} - {viernes}: 11-20h,
      <br/>{sabado}: cita previa</p>
      <h3>{texto_form}</h3>
      <form 
        name="form_T2dKZOpI"
        className="form form_T2dKZOpI" 
        id="form_T2dKZOpI" 
        action={form_actions[locale + '-' + type]} 
        method="post"
      >
        <div style={{ display: 'none' }}>
          <input type="hidden" name="_wpcf7" defaultValue="4051"/>
          <input type="hidden" name="_wpcf7_version" defaultValue="5.1.1"/>
          <input type="hidden" name="_wpcf7_locale" defaultValue="es_ES"/>
          <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f4051-p68-o1"/>
          <input type="hidden" name="_wpcf7_container_post" defaultValue="68"/>
          <input type="hidden" name="g-recaptcha-response" defaultValue=""/>
        </div>
        <div className="input">
          <label htmlFor="name">{field_name}</label><br/>
          <span className="wpcf7-form-control-wrap name">
            <input type="text" id="name" name="name" defaultValue="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/>
          </span>
        </div>
        <div className="input">
          <label htmlFor="phone">{field_phone}</label><br/>
          <span className="wpcf7-form-control-wrap phone">
            <input type="text" id="phone" name="phone" defaultValue="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"/>
          </span>
        </div>
        <div className="input" style={{display:'none'}}>
          <label htmlFor="email">{field_email}</label><br/>
          <span className="wpcf7-form-control-wrap email">
            <input type="email" id="email" name="email" defaultValue="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false"/>
          </span>
        </div>
        <div className="input">
          <label htmlFor="email_r">{field_email}</label><br/>
          <span className="wpcf7-form-control-wrap email">
            <input type="email" id="email_r" name="email_r" defaultValue="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false"/>
          </span>
        </div>
        <div className="input">
          <label htmlFor="notes">{field_message}</label><br/>
          <span className="wpcf7-form-control-wrap notes">
            <textarea id="notes" name="notes" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false"></textarea>
          </span>
        </div>
        <p>
          <span className="wpcf7-form-control-wrap privacidad">
            <span className="wpcf7-form-control wpcf7-acceptance">
              <span className="wpcf7-list-item">
                <label>
                  <input type="checkbox" name="privacidad" defaultValue="1" aria-invalid="false" required/>
                  <span className="wpcf7-list-item-label">
                    { isES ? (
                        <>
                        He leído y acepto las <a href="/aviso-legal" target="_blank" style={{textDecoration:'underline'}}> políticas de privacidad</a>
                        </>
                      ) : (
                        <>
                          I have read and accept the <a href="/en/legal-advice" target="_blank" style={{textDecoration:'underline'}}>privacy policies</a> 
                        </>
                      )
                    }
                  </span>
                </label>
              </span>
            </span>
          </span>
        </p>
        <p><input type="submit" value={isES ? 'Enviar' : 'Send'} className="wpcf7-form-control wpcf7-submit button"/></p>
        <div className="wpcf7-response-output wpcf7-display-none"></div>
        <input type="hidden" name="tags[]" value={ tag_split[ tag_split.length-1 ]} style={{display:'none'}} />
      </form>
    </>
  )
}


ContactForm.propTypes = {
  tag: PropTypes.string,
  type: PropTypes.string,
}

ContactForm.defaultProps = {
  tag: ``,
  type: `contacto`,
}


export default ContactForm
