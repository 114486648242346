import React from "react"
import Iframe from 'react-iframe'

const Mapa = () => (
  <Iframe 
    title="Google Maps"
    url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23941.028390579384!2d2.1420367936638134!3d41.403872514708056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2bc2bcf9ef9%3A0x313d5d56cac706ce!2sPAOLA+CAROLINA+BARCELONA!5e0!3m2!1ses!2ses!4v1536060013714" 
    width="100%" 
    height="600" 
    frameborder="0" style={{ border:'0',borderRadius:'.4em' }}
    allowfullscreen
  /> 
)

export default Mapa
